import { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

if (/MSIE|Trident/.test(window.navigator.userAgent)) {
  document.body.innerHTML += document.querySelector('#noIeTemplate')!.textContent;
} else {
  render(
    <StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
  );
}

document.body.removeChild(document.querySelector('#noIeTemplate')!);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
