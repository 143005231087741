import { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Wrapper from "../../components/Wrapper";
import YoutubeFacade from "../../components/YouTubeFacade";
import EventList from '../../components/EventList';
import Slideshow from '../../components/Slideshow';

import styles from './Home.module.scss';

import mitmachenBanner from '../../assets/mitmachen.png';
import ytLogo from '../../assets/yt_logo_rgb_dark.png';
import ts3logo from '../../assets/ts_logo_horizontal_rgb.svg';

import { ReactComponent as IconController } from '../../assets/icon-controller.svg';
import { ReactComponent as IconLightbulb } from '../../assets/icon-lightbulb.svg';
import { ReactComponent as IconGroup } from '../../assets/icon-group.svg';
import { ReactComponent as IconTwitch } from '../../assets/logo-twitch.svg';
import { ReactComponent as IconTwitter } from '../../assets/logo-twitter.svg';
import { DiscordWidget, TwitterWidget, TeamSpeakWidget } from '../../components/Widgets';
import W from '../../components/W';
import PubAm from '../../components/PubAm';

const showTs = document.body.classList.contains('grpw-ts');

const Home = () => {
  const [banner, setBanner] = useState<string[]>([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/banner.json`).then((response) => response.json().then(images => setBanner(images)));
  }, []);

  return (<Fragment>
    <div className={styles.introHeader}>
      {(banner && banner.length > 0) && <Slideshow images={banner} />}
      <Wrapper>
        <img width={300} height={300} src={`${process.env.PUBLIC_URL}/logo250.png`} alt="Gruppe W Logo" loading='lazy' className={styles.introLogo} />
      </Wrapper>
    </div>
    <Wrapper className={clsx(styles.container, styles.bottom)}>
      <EventList />
    </Wrapper>
    <PubAm />
    <Wrapper className={clsx(styles.container, styles.bottom, styles.top)} id="uber-uns">
      <h2 className={styles.headline}>Über uns</h2>
      <p style={{ fontStyle: 'italic' }}>Hallo, dürfen wir uns kurz vorstellen?</p>
      <Row>
        <Col sm>
          <IconGroup className={styles.icon80} />
          <p>Wir sind eine 2012 gegründete Gemeinschaft und unser Ziel ist es, ArmA mit all seinen Facetten als Militärsimulation - ganz nach dem Motto <strong>"Tactical Gaming"</strong> - voll auszuleben. Dabei versuchen wir immer wieder noch einen Schritt weiterzugehen und zielen auf eine große Vielfältigkeit bei den gespielten Einheitenarten und deren gemeinsamen Wirken ab.</p>
        </Col>
        <Col sm>
          <IconController className={styles.icon80} />
          <p>Wir beschreiben uns als <strong>"eventorientierte"</strong> Gemeinschaft: Wir verabreden uns zu einer festen Zeit, um ArmA in einem individuell durch unsere Missionsbauer erstellten Szenario zu erleben. Jede Spielerin und jeder Spieler in unseren Events hat für den Abend eine feste und wichtige Aufgabe, damit der Abend ein Erfolg und das Missionsziel gemeinsam erreicht werden kann. Sei es als einfacher Rifleman, als Sanitäter, als Unterstützungskraft in der Logistik oder als Gesamtführung der Mission - jeder Slot ist wichtig.</p>
        </Col>
        <Col sm>
          <IconLightbulb className={styles.icon80} />
          <p><strong>Vorbereitung</strong> ist dabei ein wichtiges Stichwort. Wenn kein Event angesetzt ist, treffen wir uns zu spontanen Missionen. Das können bereits gespielte Events oder ein individuelles Szenario mit dem Zeus-Modul sein. Diese spontanen Spieleabende können auch dazu genutzt werden, sich auf einen Slot vorzubereiten oder lernen. Die Basics dazu stehen in unserem eigenen <strong>Wiki</strong> und öfters werden Trainings zu bestimmten Themen abgehalten.</p>
        </Col>
      </Row>
      <p><strong>Du bist daher herzlich eingeladen</strong>, bei uns <a href="https://mitmachen.gruppe-w.de" target="_blank" rel="noreferrer">mitzumachen</a>, auch wenn du noch keine Erfahrung im taktischen ArmA-Gameplay gemacht haben solltest.</p>
      <h3>Taktik | Teamwork | Atmosphäre</h3>
      <div style={{ maxWidth: 700, margin: '0 auto' }}>
        <YoutubeFacade ytId="7Qr_M_Jk5FM" listId="PLfUJsFpkGh3utzDccP-OA8c4oIZsJuG7O" />
      </div>
    </Wrapper>
    <Wrapper className={clsx(styles.container, styles.bottom, styles.top)} id="mitmachen">
      <h2 className={styles.headline}>Mitmachen</h2>
      <p>Du möchtest bei uns mitspielen? Aber gerne doch!</p>
      <p>Auf unserer <Link to="/mitmachen">Mitmachen-Seite</Link> findest du alle wichtigen Informationen und ein Formular, mit dem du dich bei uns registrieren kannst.</p>
      <img src={mitmachenBanner} alt="Mitmachen Banner" loading='lazy' height={119} width={1000} />
    </Wrapper>
    <Wrapper className={clsx(styles.container, styles.bottom, styles.top)} id="media">
      <h2 className={styles.headline}>Media</h2>
      <Row middle="xs" style={{ paddingBottom: 30 }}>
        <Col sm={3}>
          <img width={199} height={45} src={ytLogo} alt='YouTube' style={{ maxHeight: 40, width: 'auto', display: 'block', margin: '0 auto' }} loading='lazy' />
        </Col>
        <Col sm={9}>
          <p>Bilder sagen mehr als viele Worte. Schau daher gerne auch auf unserem <a href='https://www.youtube.com/channel/UCy_EnIO-ZGhz12n_ZbP0kGQ' target='_blank' rel='noreferrer'>YouTube-Kanal</a> vorbei.<br />Hier findest du <strong>Mitschnitte unserer Missionen</strong>, VOD's von unseren Live-Streams oder andere interessante Inhalte.</p>
        </Col>
      </Row>
      <Row middle="xs" style={{ paddingBottom: 30 }}>
        <Col sm={3}>
          <IconTwitch className={styles.icon60}  />
        </Col>
        <Col sm={9}>
          <p>Wir sind auch Live unterwegs - In unseren <strong>Streams</strong> auf unserem <a href='https://www.twitch.tv/gruppe_w/about' target='_blank' rel='noreferrer'>Twitch-Channel</a> siehst du unser ungeschnittenes Gameplay und kannst dir ein Bild von unseren Missionen machen.</p>
        </Col>
      </Row>
      <Row middle="xs" style={{ paddingBottom: 30 }}>
        <Col sm={3}>
          <IconTwitter className={styles.icon60} />
        </Col>
        <Col sm={9}>
          <p>Aktuelles von Gruppe <W/> oder aus der ArmA-Community findest du in unserem <a href='https://twitter.com/GruppeWOfficial' target='_blank' rel='noreferrer'>Twitter-Feed</a>. Bleib auf dem Laufenden und folge unserem Profil.</p>
        </Col>
      </Row>
    </Wrapper>
    <Wrapper className={clsx(styles.container, styles.bottom, styles.top)} id="ts-sponsor" style={{textAlign: 'center'}}>
      <p className={styles.enbold}>Gruppe <W/> wird unterstützt von:</p>
      <a href='https://teamspeak.com/de/?utm_source=sponsor&utm_campaign=gruppe-w' target="_blank" rel='noreferrer' title="TeamSpeak">
        <img width={330} height={96} src={ts3logo} alt="TeamSpeak Logo" loading='lazy' />
      </a>
    </Wrapper>
    <Wrapper className={clsx(styles.container, styles.top)} id="social">
      <h2 className={styles.headline}>Social</h2>
      <Row center="md">
        <Col md={4}>
          <DiscordWidget />
        </Col>
        <Col md={4}>
          <TwitterWidget profileName="GruppeWOfficial" height={400} />
        </Col>
        {showTs && <Col md={4}>
          <TeamSpeakWidget />
        </Col>}
      </Row>
    </Wrapper>
  </Fragment>);
}

export default Home;
