type NavConfigEntry = {
  title: string,
  target: string
} | {
  title: string,
  url: string
};

const navConfig: NavConfigEntry[] = [
  {
    title: 'Über uns',
    target: '/#uber-uns'
  },
  {
    title: 'Mitmachen',
    target: '/mitmachen',
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/DSJYQ8G'
  },
  {
    title: 'Media',
    target: '/#media'
  },
  {
    title: 'Forum',
    url: 'https://forum.gruppe-w.de'
  },
  {
    title: 'Wiki',
    url: 'https://wiki.gruppe-w.de'
  },
  {
    title: 'Mitglieder',
    url: 'https://forum.gruppe-w.de/viewpage.php?page_id=4'
  }
];

export default navConfig;
