import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavHashLink } from 'react-router-hash-link';
import ScrollLock from 'react-scrolllock';
import styles from './Navbar.module.scss';
import Wrapper from '../Wrapper';

import navConfig from './navConfig';
import W from '../W';

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({ top: yCoordinate - 40, behavior: 'smooth' });
}

const DrawerMenuPortal: React.FC = ({ children }) => createPortal(children, document.body, 'navMenuPortal');

const useTransition = ({ show, timeout = 300 }: { show: boolean, timeout?: number }) => {
  const [render, setRender] = useState(show);
  const timeoutRef = useRef<number|null>(null);

  useEffect(() => {
    const updateRender = () => setRender(show);

    show ? updateRender() : (timeoutRef.current = window.setTimeout(updateRender, timeout));

    return () => window.clearTimeout(timeoutRef.current!);
  }, [show, timeout]);

  return [render];
}

const DrawerMenu: React.FC<{ closeMenu: () => void, open: boolean }> = ({ closeMenu, open, ...props }) => {
  const [shouldRender] = useTransition({ show: open });

  return shouldRender ? (
    <DrawerMenuPortal>
      <ScrollLock accountForScrollbars>
        <div className={clsx(styles.drawerMenu, {
          [styles.leaving]: !open
        })}>
          <div className={styles.drawerHeader}>
            <button className={styles.closeBtn} onClick={() => closeMenu()}></button>
          </div>
          <nav className={styles.drawerNavContainer}>
            <ul className={styles.drawerNav}>
              {navConfig.map(navItem => (<li key={`.nav$${navItem.title.toLowerCase()}`}>
                {'target' in navItem && <NavHashLink onClick={() => closeMenu()} smooth scroll={(el) => {
                  scrollWithOffset(el);
                }}  className={clsx(styles.drawerNavItem)} to={navItem.target} activeClassName={styles.active}>{navItem.title}</NavHashLink>}
                {!('target' in navItem) && <a className={clsx(styles.drawerNavItem)} href={navItem.url}>{navItem.title}</a>}
              </li>))}
            </ul>
          </nav>
        </div>
      </ScrollLock>
      <div className={clsx(styles.drawerShadow, { [styles.leaving]: !open })} onClick={() => closeMenu()} />
    </DrawerMenuPortal>
  ) : null;
}

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const updateMobile = () => setIsMobile(window.innerWidth <= 900);

  useEffect(() => {
    window.addEventListener('resize', updateMobile);

    return () => window.removeEventListener('resize', updateMobile);
  }, []);

  return (<header className={styles.header}>
    <Wrapper className={styles.wrap}>
      <NavHashLink to='/' title='Startseite' className={styles.brand}>
        <img src={`${process.env.PUBLIC_URL}/logo50.png`} alt="Gruppe W Logo" width={50} height={50} />
        <div className={styles.brandLine}><span className={styles.valignMiddle}>Gruppe</span> <W className={clsx(styles.brandLetter, styles.valignMiddle)} /></div>
      </NavHashLink>
      {isMobile && <button onClick={() => setMenuOpen(true)} className={styles.burger}>
        <div className={styles.bun} />
        <div className={styles.bun} />
        <div className={styles.bun} />
      </button>}
      {!isMobile && <nav>
        <ul className={styles.navMenu}>
          {navConfig.map(navItem => (<li key={`.nav$${navItem.title.toLowerCase()}`}>
            {'target' in navItem && <NavHashLink smooth scroll={scrollWithOffset} className={styles.navMenuItem} to={navItem.target} activeClassName={styles.active}>{navItem.title}</NavHashLink>}
            {!('target' in navItem) && <a className={clsx(styles.navMenuItem)} href={navItem.url}>{navItem.title}</a>}
          </li>))}
        </ul>
      </nav>}
    </Wrapper>
    {isMobile && <DrawerMenu closeMenu={() => setMenuOpen(false)} open={menuOpen} />}
  </header>);
}

export default Navbar;
