import { useEffect, useRef } from 'react';
import scriptjs from 'scriptjs';

import styles from './widgets.module.scss';

const twitterJs = 'https://platform.twitter.com/widgets.js';

const TwitterWidget: React.FC<{ profileName: string, height?: number }> = ({ profileName, ...props }) => {
  const container = useRef<HTMLDivElement|null>(null);

  useEffect(() => {
    container.current!.innerHTML = '';

    const widgetArgs = [
      {
        sourceType: 'profile',
        screenName: profileName
      },
      container.current,
      {
        theme: 'dark',
        height: 400,
        chrome: 'nofooter',
        id: 'profile:GruppeWOfficial'
      }
    ];

    if ('twttr' in global) {
      container.current && (window.twttr as Twitter).widgets.createTimeline(...(widgetArgs  as [TwitterTimelineWidgetDataSource, HTMLElement, TwitterTimelineWidgetOptions]));
    } else {
      scriptjs(twitterJs, 'twitterEmbed', () => {
        (container.current && window.twttr) && (window.twttr as Twitter).widgets.createTimeline(...(widgetArgs  as [TwitterTimelineWidgetDataSource, HTMLElement, TwitterTimelineWidgetOptions]));
      });
    }
  }, []); // eslint-disable-line

  return (<div ref={container} className={styles.widget} style={{ backgroundColor: '#292F33' }}></div>)
}

export default TwitterWidget;
