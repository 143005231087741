import clsx from "clsx";
import React from "react";
import Wrapper from "../Wrapper";

import styles from './index.module.scss';

const Banner: React.FC<{imgSrc: string, imgOffset?: number} & React.HTMLAttributes<HTMLDivElement>> = ({ children, imgSrc, className, imgOffset=50, ...props }) => <div className={styles.container}>
  {imgSrc && <img src={imgSrc} className={styles.img} alt='' loading='lazy' style={{'--banner-offset': `${imgOffset}%`}} />}
  <Wrapper className={clsx(styles.content, className)} {...props}>
    {children}
  </Wrapper>
</div>;

export default Banner;
