import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";

import styles from './Slideshow.module.scss';

const Slideshow: React.FC<{ images: string[] }> = ({ images = [], ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * images.length));
  const [nextIndex, setNextIndex] = useState<number|null>(null);

  useEffect(() => {
    if (images.length <= 1) return;

    let timeout1: number, timeout2: number;

    timeout1 = window.setTimeout(() => {
      setNextIndex(() => {
        let _nextIndex = images.length <= currentIndex + 1 ? 0 : currentIndex + 1;

        timeout2 = window.setTimeout(() => {
          setCurrentIndex(_nextIndex);
          setTimeout(() => setNextIndex(null), 100);
        }, 500);

        return _nextIndex;
      })
    }, 5000);

    return () => [timeout1, timeout2].forEach(clearTimeout);
  }, [currentIndex, images]);

  return images.length > 0 ? <Fragment>
    <img src={images[currentIndex]} key='.currentSlide' className={styles.slideshowImg} alt='' />
    {nextIndex !== null && <img src={images[nextIndex]} key='.nextSlide' className={clsx(styles.slideshowImg, styles.appear)} alt='' />}
  </Fragment> : null;
}

export default Slideshow;
