import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Loader from './components/Loader';

import styles from './App.module.scss';

import Home from './pages/Home';
import NotFound from './pages/NotFound';

const MarkdownPage = lazy(() => import('./pages/MarkdownPage'));
const Participation = lazy(() => import('./pages/Participation'));

function App() {
  return (
    <Router>
      <div className={styles.app}>
        <Helmet titleTemplate='Gruppe W - %s' defaultTitle='Gruppe W' meta={[{ name: 'description', content: 'Wir sind eine 2012 gegründete Gemeinschaft und unser Ziel ist es, ArmA mit all seinen Facetten als Militärsimulation - ganz nach dem Motto "Tactical Gaming" - voll auszuleben.' }]} />
        <Navbar />
        <main className={styles.page}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path='/'>
                <Home />
              </Route>
              <Route exact path='/mitmachen'>
                <Participation />
              </Route>
              <Route exact path='/impressum'>
                <MarkdownPage title="Impressum" contentUrl='impressum.md' />
              </Route>
              <Route exact path='/datenschutz'>
                <MarkdownPage title="Datenschutzerklärung" contentUrl='datenschutz.md' />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
