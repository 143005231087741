import { useEffect, useState } from "react";

import { parseISO, format } from 'date-fns'
import de from 'date-fns/locale/de';

import styles from './EventList.module.scss';
import W from '../W';

const endpoint = 'https://forum.gruppe-w.de/custom/event_overview.php';

type EventEntry = {
  thread: string,
  type: string,
  title: string,
  slotCount: number,
  availableSlots: number,
  eventDate: string,
  unlockDate: string
};

const Event: React.FC<{ event: EventEntry }> = ({ event, ...props }) => {
  const { thread, type, title, slotCount, availableSlots } = event;

  const eventDate = parseISO(event.eventDate);
  const unlockDate = parseISO(event.unlockDate);

  const slotsTaken = slotCount - availableSlots;

  return (<a className={styles.event} href={`https://forum.gruppe-w.de/forum/viewthread.php?thread_id=${thread}`} target='_blank' rel="noreferrer" title='Zum Event-Thread'>
      <div className={styles.eventTitle}>{`[${type}${slotCount}] ${title}`}</div>
      <div className={styles.infoContainer}>
        <div>
          <div><time dateTime={event.eventDate} title={format(eventDate, 'PPPPp', { locale: de })}>{format(eventDate, 'dd.MM.yyyy - HH:mm \'Uhr\'', { locale: de })}</time></div>
          <div>Freischaltung: <time dateTime={event.unlockDate} title={format(unlockDate, 'PPPPp', { locale: de })}>{format(unlockDate, 'dd.MM. \'ab\' HH:mm \'Uhr\'', { locale: de })}</time></div>
        </div>
        <progress title={`${slotsTaken} von ${slotCount} Slots belegt`} className={styles.eventCapacity} max={slotCount} value={slotsTaken} />
      </div>
  </a>);
}

const EventList = ({ ...props }) => {

    const [events, setEvents] = useState<EventEntry[]>([]);

    useEffect(() => {
        let xhr = new XMLHttpRequest();

        xhr.open('GET', endpoint, true);

        xhr.addEventListener('load', () => setEvents(JSON.parse(xhr.responseText)));

        xhr.send();

        return () => xhr.abort();
    }, []);

    return (<div className={styles.container}>
        <div className={styles.title}>Anstehende Events bei Gruppe&nbsp;<W/></div>
        <div className={styles.eventList}>
        {!!events.length && events.map((event, indx) => <Event event={event} key={`.event$${event.thread}`} />)}
        {!events.length && <div className={styles.noResults}>
          <div>Keine anstehenden Events vorhanden.</div>
        </div>}
        </div>
    </div>);
};

export default EventList;
