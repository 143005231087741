import Wrapper from "../Wrapper"

import styles from './Footer.module.scss';
import { Link } from "react-router-dom";
import W from "../W";

const currentYear = (new Date()).getUTCFullYear();

const Footer = () => {
    return (<footer className={styles.footer}>
        <Wrapper className={styles.container}>
            <div className={styles.copy}>Gruppe <W/> - &copy; {currentYear}</div>
            <ul className={styles.menu}>
                <li><Link to='/impressum'>Impressum</Link></li>
                <li><Link to='/datenschutz'>Datenschutzerklärung</Link></li>
            </ul>
        </Wrapper>
    </footer>);
}

export default Footer;
