import { useEffect, useState } from "react";

import styles from './YouTubeFacade.module.scss';

const YouTubeFacade: React.FC<{ ytId: string, listId?: string }> = ({ ytId, listId, ...props }) => {
    const [render, setRender] = useState(false);
    const [url, setUrl] = useState('');

    const onFacadeClick = () => setRender(true);

    useEffect(() => {
      setUrl(listId ? `https://www.youtube-nocookie.com/embed/videoseries?list=${listId}` : `https://www.youtube-nocookie.com/embed/${ytId}`);
    }, [ytId, listId]);

    return (<div className={styles.container}>
        {!render && <div onClick={onFacadeClick} className={styles.facade} style={{ backgroundImage: `url(${process.env.NODE_ENV === 'production' ? `/ytimg/${ytId}.webp` : `https://i.ytimg.com/vi_webp/${ytId}/sddefault.webp`})` }}>

        </div>}
        {render && <iframe className={styles.frame} title="YouTube Video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen src={url}/>}
    </div>);
}

export default YouTubeFacade;
