/* eslint react-hooks/rules-of-hooks: 0 */

import styles from './PubAm.module.scss';

import Banner from '../Banner';
import W from '../W';
import { useEffect, useState } from 'react';
import Loader from '../Loader';

interface PubAmData {
  hide?: boolean,
  imgSrc: string,
  imgOffset: number,
  link: string,
  title: string,
  dates: Array<string>
}

const usePubAmData = (new (class PubAmHook extends Function {
  __self__: ThisParameterType<PubAmHook>;

  static data: PubAmData|null = null;

  constructor() {
    super();

    return new Proxy(this, {
      apply: (target, thisArg, argArray) => target.__call__(),
    });
  }

  __call__() {
    const [loading, setLoading] = useState(PubAmHook.data === null);
    const [data, setData] = useState(PubAmHook.data);

    useEffect(() => {
      if (data) return;

      (async () => {
        try {
          const response = await fetch('/pubAm.json');

          if (response.ok) {
            const data = await response.json();
            setData(PubAmHook.data = data);
          }
        } catch(err) {}
        finally {
          setLoading(false);
        }
      })();
    });

    return {loading, data};
  }
})() as unknown as () => { loading: boolean, data: PubAmData|null });

const PubAm = () => {
  const { loading, data } = usePubAmData();

  if (loading) return <Loader />;

  if (!data || data.hide) return null;

  return <Banner imgSrc={data.imgSrc} style={{ textAlign: 'center' }} imgOffset={data.imgOffset}>
    <p className={styles.supertitle}>Gruppe <W/> Lädt ein: <br />Public Abendmission</p>
    <h3 className={styles.pubAmHeadline}>{data.title}</h3>
    <p style={{ fontWeight: 'bold', fontSize: 20 }}>{data.dates.join(' | ')}</p>
    <a target='_blank' rel='noreferrer' className={styles.linkBox} href={data.link}>Alle Infos findest du in unserem Forum</a>
  </Banner>;
}

export default PubAm;
